import React, { useEffect, useState } from "react";
import { Box, Container, Typography } from "@mui/material";
import Layout from "../components/base-structure/Layout";
import { graphql } from "gatsby";
import PopularBlogs from "../components/PopularBlogs/PopularBlogs";
import "../styles/Blog.scss";
import Seo from "../components/common/SEO";
import ReactPaginate from "react-paginate";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import BlogBox from "../components/common/BlogBox";

const content = [
  {
    id: 1,
    metaTitle: "Latest Updates, News, Tips, & Guidelines  - Invocom Blog",
    metaDescription:
      "Welcome to the official blog of Invocom because every communication matters. Discover the latest product news, innovative features and AI chatbot usage.",
  },
];

export default function Blog({ data }) {
  const items = data?.allStrapiBlog?.nodes;
  const [searchTerm, setSearchTerm] = useState("");
  const [filteredArray, setFilteredArray] = useState([]);

  let itemsPerPage = 4;
  const [itemOffset, setItemOffset] = useState(0);
  const endOffset = itemOffset + itemsPerPage;
  const currentItems = filteredArray.slice(itemOffset, endOffset);
  const pageCount = Math.ceil(filteredArray.length / itemsPerPage);

  const handlePageClick = (event) => {
    const newOffset = (event.selected * itemsPerPage) % filteredArray.length;
    setItemOffset(newOffset);
  };

  useEffect(() => {
    const results = items.filter((item) =>
      item.Title.toLowerCase().includes(searchTerm.toLowerCase())
    );
    setFilteredArray(results);
  }, [searchTerm, items]);

  useEffect(() => {
    window.scrollTo({ top: 0 });
  }, [currentItems]);

  return (
    <Layout>
      {content.map((contents) => (
        <div key={contents.id}>
          <Seo
            title={contents.metaTitle}
            description={contents.metaDescription}
            tag="index"
            pageUrl="blog/"
          />
        </div>
      ))}
      <Box className="blogPage">
        <Container>
          <Box className="blogHeading">
            <Typography variant="h1">Our Blogs</Typography>
          </Box>
          <Box className="blogContent">
            <Box className="paginationSection">
              <Box className="cardsLayout">
                {currentItems.map((blog, index) => {
                  return <BlogBox blog={blog} key={index} indexProp={true} />;
                })}
              </Box>
            </Box>
            <Box className="blogSearchWrapper">
              <Box className="searchField">
                <input
                  onChange={(e) => setSearchTerm(e.target.value)}
                  placeholder="Search Blogs ... "
                  value={searchTerm}
                />
              </Box>
              <PopularBlogs data={data} />
            </Box>
          </Box>
          <Box className="blogPaginationWrapper">
            <ReactPaginate
              activeClassName="item active "
              breakClassName="item break-me "
              containerClassName={"pagination"}
              disabledClassName={"disabled-page"}
              marginPagesDisplayed={2}
              nextClassName={"item next "}
              pageClassName={"item pagination-page "}
              previousClassName={"item previous"}
              nextLabel={<ArrowForwardIcon style={{ fontSize: 18 }} />}
              previousLabel={<ArrowBackIcon style={{ fontSize: 18 }} />}
              breakLabel="..."
              onPageChange={handlePageClick}
              pageRangeDisplayed={5}
              pageCount={pageCount}
              renderOnZeroPageCount={null}
            />
          </Box>
        </Container>
      </Box>
    </Layout>
  );
}
export const query = graphql`
  query MyQuery {
    allStrapiBlog(sort: { fields: createdAt, order: DESC }) {
      nodes {
        Slug
        Content
        Title
        id
        Image {
          url
        }
        createdAt
        author_profile {
          Name
          Slug
        }
        categories {
          Name
          Slug
        }
        check {
          data {
            check
          }
        }
      }
    }
  }
`;
