import { Box, Typography } from "@mui/material";
import React, { useState, useEffect } from "react";
import { Link } from "gatsby";
import "./PopularBlogs.scss";

export default function PopularBlogs({ data }) {
  const filteredList = data?.allStrapiBlog?.nodes;
  const [stickyClass, setStickyClass] = useState("");

  useEffect(() => {
    window.addEventListener("scroll", stickNavbar);
    return () => window.removeEventListener("scroll", stickNavbar);
  }, []);

  const stickNavbar = () => {
    if (window !== undefined) {
      let windowHeight = window.scrollY;
      // window height changed for the demo
      windowHeight > 110 ? setStickyClass("sticky-nav") : setStickyClass("");
    }
  };

  return (
    <Box className={`helloSticky ${stickyClass}`}>
      <Box className="popularBlog">
        <Typography variant="h2">Most Popular</Typography>
      </Box>
      <Box className="filterContent">
        {filteredList.slice(0, 5).map((item, index) => (
          <Box key={index}>
            <Link to={`/blog/${item?.Slug}`}>
              <Box
                className="searchCardWrapper"
                sx={{
                  display: "flex",
                  columnGap: "10px",
                  border: "1px solid #00000026",
                  padding: "10px",
                  borderRadius: "5px",
                  "&:hover": {
                    backgroundColor: "#FBF7EE",
                  },
                }}
              >
                <img
                  src={item.Image[0].url}
                  style={{ maxWidth: "115px" }}
                  alt="blog"
                />
                <Box className="searchBoxContent">
                  <Typography variant="h3" key={index}>
                    {item.Title}
                  </Typography>
                  <Typography variantMapping="p">
                    By {item.author_profile.Name}{" "}
                    <span style={{ fontStyle: "normal" }}>|</span>{" "}
                    {item.categories[0].Name}
                  </Typography>
                </Box>
              </Box>
            </Link>
          </Box>
        ))}
      </Box>
    </Box>
  );
}
